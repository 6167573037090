import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Article from "../components/section/article";

export default function Pricing() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiPricing.seo} slug={data.strapiPricing.slug_en}  lang="en" currentId="3">
      <Article list={data.strapiPricing.article} lang="en" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiPricing {
      slug_en
      seo {
        meta_title_en
        meta_description_en
      }
      article {
        type
        subtext_en
        text_en
        button {
          label
          url
        }
      }
    }
  }
`;