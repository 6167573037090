import React from "react"
import Button from "../ui/button"

const Article = ({ list, lang }) => {

  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
  }

  const newList = [];
  list.forEach((item) => {
    switch(lang) {
      case 'en':
        newList.push({
          type: item.type,
          text: item.text_en,
          subtext: item.subtext_en,
          button: item.button
        });
        break;
      case 'fr':
        newList.push({
          type: item.type,
          text: item.text_fr,
          subtext: item.subtext_fr,
          button: item.button
        });
        break;
      case 'de':
        newList.push({
          type: item.type,
          text: item.text_de,
          subtext: item.subtext_de,
          button: item.button
        });
        break;
      case 'it':
        newList.push({
          type: item.type,
          text: item.text_it,
          subtext: item.subtext_it,
          button: item.button
        });
        break;
      default:
        break;
    }
  });

  return (
    <section className="article">
      { Array.isArray(newList) &&
      <div className="article__main">
        { newList.map((item, index) => (
          <div id={'section' + index} key={index} className={item.type} data-animate>
            <div className={item.type + '__text'} dangerouslySetInnerHTML={{
              __html: item.text
            }} />
            { item.type === 'lead' &&
              <div className={item.type + '__bottom'}>
                { item.subtext !== null &&
                <div className={item.type + '__subtext'} dangerouslySetInnerHTML={{
                  __html: item.subtext
                }} />
                }
                { item.button !== null &&
                <div className={item.type + '__button'}>
                  <Button className="gradient" label={item.button.label} url={item.button.url} />
                </div>
                }
              </div>
            }
          </div>
        ))}
      </div>
      }
    </section>
  );
}

export default Article